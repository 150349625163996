import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout/Layout"
import { GridContainer, GridItemFullWidth } from "../components/layout/Grid"
import Seo from "../components/Seo"
import H2Header from "../components/H2Header"
import PostCategories from "../components/PostCategories"

const BlogpostTemplate = ({
  data: {
    mdx: {
      excerpt,
      body,
      frontmatter: { title, date, isoDate, categories = [] },
      fields: { author },
    },
  },
}) => (
  <Layout>
    <Seo title={title} description={excerpt} />
    <GridContainer>
      <GridItemFullWidth>
        <article>
          <header>
            <H2Header>{title}</H2Header>
            <p>
              Written by{" "}
              <Link to={`/team/${author.id}`}>{author.fullName}</Link> -{" "}
              <time dateTime={isoDate}>{date}</time>
            </p>

            {categories && <PostCategories categories={categories} />}
          </header>
          <section>
            <MDXRenderer>{body}</MDXRenderer>
          </section>
        </article>
      </GridItemFullWidth>
    </GridContainer>
  </Layout>
)

export default BlogpostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 150)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        isoDate: date
        categories
      }
      fields {
        author {
          id
          fullName
          image
          about
        }
      }
    }
  }
`
